import { fallbackLng } from "./lib/i18n/settings";

type SitesStruct = {
    defaultLang: string[];
    defaultLoc: string[];
    custom: Record<string, string[]>;
    ignoreFolders: string[];
    backends: Record<string, string>;
    hostPanelBackend: string;
    gtmCode: Record<string, string | undefined>;
    googleVerification: Record<string, string | undefined>;
};

export const SITES: SitesStruct = {
    defaultLang: ["en", "de", "cn", "es", "fr", "it", "pt", "ru", "tr"],
    defaultLoc: ["https://www-v2-dev.myroom24.dev", "https://www-v2.myroom24.dev", "https://frontend-show.pages.dev/"],
    custom: {
        // first host/domain is treated as main - we generate sitemap.xml for it, others get prefixed filenames
        de: ["https://myroom24.de", "https://preprod.myroom24.de", "https://show.myroom24.de", "http://localhost:8001"],
        en: ["https://myroom24.com", "https://preprod.myroom24.com", "https://show.myroom24.com", "http://localhost:8002"],
    },

    // local dev test - run with docker compose to test
    // custom: {
    //     // first host/domain is treated as main - we generate sitemap.xml for it, others get prefixed filenames
    //     de: ["http://localhost:8001", "https://preprod.myroom24.de", "https://myroom24.de"],
    //     en: ["http://localhost:8002", "https://preprod.myroom24.com"],
    // },

    // can also include forward slashes for deeper folders, e.g.: "blog/tag"
    ignoreFolders: [
        "billing",
        "checkout",
        "invoices",
        "messages",
        "payment",
        "reservations",
        "security",
        "user-profile",
        "press",
        "presse",
    ],
    backends: {
        en: "https://api.myroom24.com/",
        de: "https://api.myroom24.de/",
    },
    hostPanelBackend: "https://api.myroom24.com/",
    gtmCode: {
        de: process.env.NEXT_PUBLIC_GOOGLE_TAGMANAGER_DE,
    },
    googleVerification: {
        de: process.env.NEXT_PUBLIC_GOOGLE_VERIFICATION_DE
    }
};

export const getLangFromHost = (host: string) => {
    for (const [lang, domains] of Object.entries(SITES.custom)) {
        if (domains.includes(host)) {
            return lang;
        }
    }
    return null;
};

export function getDomain(language: string) {
    let domain = "";
    let isCustomDomain = false;
    if (process.env.NEXT_PUBLIC_PRODUCTION_BUILD === "true") {
        // check if language is in SITES.custom and use first domain from there as link
        isCustomDomain = Object.keys(SITES.custom).includes(language);
        domain = SITES.custom[isCustomDomain ? language : fallbackLng][0];
        if (typeof window !== "undefined" && domain.split("://")[1].replaceAll("/", "") === window.location.host) {
            domain = "";
        }
    }
    if (!domain.endsWith("/")) {
        domain = domain + "/";
    }
    return { domain, isCustomDomain };
}

export function getBackend(lang: string) {
    let backendHost = process.env.NEXT_PUBLIC_BACKEND_HOST;
    if (process.env.NEXT_PUBLIC_PRODUCTION_BUILD === "true") {
        backendHost = SITES.backends[Object.keys(SITES.backends).includes(lang) ? lang : fallbackLng];
    }
    return backendHost;
}
